// Optimizely Test WABT-139 and WABT-144 are looking to see if the product can ship in the next 5 days.
import { MaterialAvailabilityKey } from '@src/types/graphql-types';
import differenceInDays from "date-fns/differenceInDays";
import { sessionStorage } from 'react-storage';
export var optimizelyAvailableWithinFiveDays = function optimizelyAvailableWithinFiveDays(availability) {
  if (!(availability !== null && availability !== void 0 && availability.date)) {
    return false;
  }

  var today = new Date();
  var availabilityDate = new Date(availability.date);
  return sessionStorage.getItem('optly-wabt139-PDPAvailabilityInStock') && availability.key === MaterialAvailabilityKey.AvailableToShipOn && differenceInDays(availabilityDate, today) < 5;
};